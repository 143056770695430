<script setup lang="ts">
import { useUser } from '~/stores/user'

const userStore = useUser()
</script>

<template>
  <button
    class="border-neutral-400 font-bold no-underline hover:border-b"
    @click="userStore.logout()"
  >
    {{ $t('base.header.logOut') }}
  </button>
</template>

<style scoped></style>